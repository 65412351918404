import React from 'react';

const TestBadge = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="98.132"
    height="106.653"
    viewBox="0 0 98.132 106.653"
  >
    <defs>
      <clipPath>
        <rect width="98.132" height="106.653" fill="none" />
      </clipPath>
    </defs>
    <path
      d="M28.153,6.988l-3.346,1.84L31.282,20.6l-2.539,1.4L22.268,10.225l-3.344,1.84L17.637,9.728l9.23-5.078Z"
      fill="currentColor"
    />
    <path
      d="M30.761,3.257l7.772-2.1.695,2.576L34.254,5.079,35.283,8.9l4.508-1.215.695,2.575-4.508,1.216,1.078,4,5.063-1.365.694,2.576L34.952,18.8Z"
      fill="currentColor"
    />
    <g>
      <g clipPath="url(#clip-path)">
        <path
          d="M49.176,12.236A1.877,1.877,0,0,0,48.695,11a5.7,5.7,0,0,0-1.126-.938Q46.9,9.625,46.11,9.17a8.435,8.435,0,0,1-1.466-1.076A5.666,5.666,0,0,1,43.5,6.616a4.756,4.756,0,0,1-.511-2.122,4.95,4.95,0,0,1,.284-2A3.562,3.562,0,0,1,44.2,1.125,3.946,3.946,0,0,1,45.644.314,6.583,6.583,0,0,1,47.45.007a9.927,9.927,0,0,1,2.193.152,5.9,5.9,0,0,1,1.7.561L50.537,3.3a4.062,4.062,0,0,0-1.178-.464,5.823,5.823,0,0,0-1.675-.171,2.248,2.248,0,0,0-1.366.417,1.276,1.276,0,0,0-.444,1.1,1.666,1.666,0,0,0,.477,1.122,6.231,6.231,0,0,0,1.126.914q.672.438,1.46.9a8.586,8.586,0,0,1,1.466,1.1,5.776,5.776,0,0,1,1.146,1.5,4.729,4.729,0,0,1,.513,2.088,5.736,5.736,0,0,1-.282,2.127,3.884,3.884,0,0,1-.958,1.519,4.34,4.34,0,0,1-1.542.941,6.6,6.6,0,0,1-2.036.372,10.022,10.022,0,0,1-2.5-.187,6.444,6.444,0,0,1-1.666-.562l.85-2.631a6.838,6.838,0,0,0,1.284.507,5.3,5.3,0,0,0,1.734.214q2.3-.083,2.234-1.875"
          fill="currentColor"
        />
        <path
          d="M65.088,5.177l-3.736-.784L58.6,17.539l-2.837-.594L58.516,3.8l-3.738-.784L55.326.4l10.31,2.162Z"
          fill="currentColor"
        />
        <path
          d="M69.5,4.047,76.764,7.52,75.613,9.926,70.965,7.7l-1.707,3.569,4.213,2.015L72.32,15.695l-4.212-2.014-1.786,3.735,4.731,2.262L69.9,22.084l-7.346-3.511Zm5.607-1.652,2.968,1.418-.3.623-3.611.516-1.93-.922Z"
          fill="currentColor"
        />
        <path
          d="M83.766,56.939a34.7,34.7,0,1,1-34.7-34.7,34.7,34.7,0,0,1,34.7,34.7"
          fill="currentColor"
        />
        <path
          d="M49.066,106.653a49.066,49.066,0,0,1-34.7-83.761,1.5,1.5,0,1,1,2.121,2.121A46.066,46.066,0,1,0,79.6,23.092a1.5,1.5,0,1,1,1.99-2.245,49.067,49.067,0,0,1-32.523,85.806"
          fill="currentColor"
        />
        <path
          d="M43.6,49.585a1.495,1.495,0,0,1,0-2.99h6.38a1.495,1.495,0,0,1,0,2.99H43.6Z"
          fill="#fff"
        />
        <path d="M43.6,55.965a1.5,1.5,0,0,1,0-2.99h6.38a1.5,1.5,0,0,1,0,2.99Z" fill="#fff" />
        <path
          d="M43.6,62.345a1.495,1.495,0,0,1-.072-2.989h6.452a1.495,1.495,0,0,1,.072,2.989H43.6Z"
          fill="#fff"
        />
        <path
          d="M61.263,76.5a9.751,9.751,0,0,1-6.732-2.677H36.162a3.9,3.9,0,0,1-3.886-3.883V38.916a3.9,3.9,0,0,1,3.885-3.885h15.4A6.361,6.361,0,0,1,56.03,36.9L60.9,41.936a6.2,6.2,0,0,1,1.8,4.4V56.994A9.806,9.806,0,0,1,61.265,76.5m.019-16.634a6.855,6.855,0,1,0,.078,0l-.078-.77ZM36.164,38.014a.9.9,0,0,0-.9.9V69.948a.9.9,0,0,0,.9.893H52.353A9.784,9.784,0,0,1,59.712,57V46.333a3.249,3.249,0,0,0-.944-2.308L53.907,39a3.337,3.337,0,0,0-2.349-.982Z"
          fill="#fff"
        />
        <path
          d="M59.833,70.067a1.5,1.5,0,0,1-1.162-.816L57.4,66.7a1.493,1.493,0,0,1,2.671-1.334l.255.513L62.05,63.8a1.493,1.493,0,0,1,2.3,1.908l-3.195,3.828A1.491,1.491,0,0,1,60,70.073Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default TestBadge;
